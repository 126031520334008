import moment from 'moment';

export const COLORS = {
  PRIMARY: '#0A3A67',
  SECONDARY: 'FFFFFF'
};

export const orderTypes = [
  { name: 'Aadhaar Card', value: 'AADHAAR_CARD' },
  'Buy',
  'Rent',
  'Sell',
  'Lease'
];

export const navList = ['About Us', 'Products', 'Our Team', 'Blogs', 'Media'];

export const statuses = [
  'Active',
  'Request Placed',
  'Verification in progress',
  'Marketing in progress',
  'Site Visit in progress',
  'Offers',
  'Documentation',
  'Registration in progress',
  'Completed',
  'Cancelled'
];

export const SideBarMenuItems = [
  'Dashboard',
  'Clients',
  'Properties',
  'Orders',
  'CallBacks',
  'Leads',
  'MAGIK',
  'Buyers',
  'ST',
  'Primary Sales',
  'Loans'
];

export const SideBarMenuItemsForChannelPartners = [
  'Dashboard',
  'Clients',
  'Properties',
  'Leads',
  'ST'
];

export const SideBarMenuItemsForPS = [
  'Dashboard',
  'Clients',
  'Properties',
  'Orders',
  'Buyers',
  'MAGIK',
  'CallBacks',
  'Leads',
  'Primary Sales'
];

export const secondCompanyList = ['Privacy Policy', 'Cookies Policy', 'Terms and Conditions'];

export const paths = {
  ADD_CALLBACK: '/add-callback',
  ADD_CLIENT: '/add-client',
  ADD_EVENT: '/create-event',
  ADD_LEAD: '/add-lead',
  ADD_ORDER: '/add-order',
  ADD_PROPERTY: '/add-property',
  ADD_ST_CLIENT: '/st/add-client',
  EDIT_CALLBACK: '/edit-callback',
  PRIMARY_SALES_ADD_BUILDER: '/primary-sales/add-builder',
  PRIMARY_SALES_EDIT_BUILDER: '/primary-sales/edit-builder'
};

export const SCREEN_BG_COLOR = '#E5EFF9';

export const roles = ['Super Admin', 'Admin', 'Branch Head'];

export const transactionTypes = {
  NON_TRANSACTIONAL: 'non-transactional',
  TRANSACTIONAL: 'transactional'
};

export const rolesEnums = {
  ADMIN: 'Admin',
  BRANCH_HEAD: 'Branch Head',
  ARCA: 'Arca',
  CHANNEL_PARTNER: 'Channel Partner',
  PORTFOLIO_MANAGER: 'Portfolio Manager',
  PS_PORTFOLIO_MANAGER: 'Primary Sales Portfolio Manager',
  PS_RELATIONSHIP_MANAGER: 'Primary Sales Relationship Manager',
  REAL_ESTATE_WEALTH_MANAGER: 'Portfolio Manager',
  RELATIONSHIP_MANAGER: 'Relationship Manager',
  SERVICE_COORDINATOR: 'Service CoOrdinator',
  ST_PORTFOLIO_MANAGER: 'Structured Transaction Portfolio Manager',
  ST_RELATIONSHIP_MANAGER: 'Structured Transaction Relationship Manager',
  SUPER_ADMIN: 'Super Admin'
};

export const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

export const STRING_SPACE_REGEX = /^[a-zA-Z\s]*$/g;

export const shouldDisableDate = (date) => {
  const formattedDate = moment(date).format('YYYY-MM-DD');
  const holidayDates = [
    '2023-06-29',
    '2023-07-29',
    '2023-08-15',
    '2023-08-25',
    '2023-09-06',
    '2023-09-18',
    '2023-09-28',
    '2023-10-02',
    '2023-10-14',
    '2023-10-23',
    '2023-10-24',
    '2023-11-01',
    '2023-11-14',
    '2023-11-30',
    '2023-11-25'
  ];

  return (
    holidayDates.includes(formattedDate) || moment(date).day() === 0 || moment(date).day() === 6
  );
};

export function stringToColor(string) {
  let hash = 0;
  let i;

  for (i = 0; i < string?.length; i += 1) {
    hash = string?.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value?.toString(16)}`?.slice(-2);
  }

  return color;
}

export function stringAvatar(name) {
  const nameParts = name?.split(' ') || [];
  return {
    children: `${nameParts[0]?.[0] || ''}${nameParts[1]?.[0] || ''}`,
    sx: {
      bgcolor: stringToColor(name),
      fontSize: '14px'
    }
  };
}

export function getColor(name) {
  switch (name) {
    case 'Lunch / Holiday':
      return 'rgba(255, 185, 100, 1)';
    case 'Others':
      return 'rgba(185, 179, 218, 1)';
    case 'Existing To Branch':
      return 'rgba(109, 177, 224, 1)';
    case 'Idle':
      return 'rgba(242, 195, 217, 1)';
    default:
      return 'rgba(242, 195, 217, 1)';
  }
}

export function getBlueShades(name) {
  switch (name) {
    case 'ANANDRATHI':
      return '#42799E';
    case 'ICICI Wealth Select':
      return '#528FB7';
    case 'HSBC Premier':
      return '#6FA1C3';
    case 'ALLEGRO':
      return '#8CB4CF';
    case 'ICICI Private Banking':
      return '#A9C7DB';
    case 'ASK Wealth Advisors':
      return '#B6D2E5';
    case 'TRUSTPLUTUS':
      return '#C3DDF0';
    case 'EasiDesi':
      return '#D0E8FB';
    case 'INVETHIQZ':
      return '#DCF3FF';
    case 'DBS BANK INDIA LTD':
      return '#E9F8FF';
    case 'Way2Wealth':
      return '#F6F4FF';
    case 'Augment Capital':
      return '#4395BB';
    case 'WEALTHY MONK':
      return '#54A7C5';
    case 'CLIENT ASSOCIATES':
      return '#65B9D0';
    case 'Forecights':
      return '#77CBD9';
    case 'WealthyIN Customer Services Private Limited':
      return '#88DEE3';
    case 'Huvabuva':
      return '#99F0ED';
    case 'Deutsche Bank':
      return '#AAF3F8';
    case 'Les Concierge':
      return '#BBF7FF';
    case 'CREDEL CAPITAL':
      return '#CCFAFF';
    case 'ASK':
      return '#DDFFFF';
    default:
      return 'rgba(242, 195, 217, 1)';
  }
}

export function getBrownShades(referralType) {
  switch (referralType) {
    case 'Website':
      return '#322202';
    case 'Wealth Management Company':
      return '#3E2828';
    case 'Individual Referral Partner':
      return '#4B3030';
    case 'Indiassetz Employee Referral':
      return '#573838';
    case 'Client Referral':
      return '#644040';
    case 'Channel Partner':
      return '#704848';
    case 'Banks':
      return '#895858';
    case 'App':
      return '#592517';
    case 'Others':
      return '#232517';
    default:
      return 'rgba(242, 195, 217, 1)';
  }
}

export function formatTimeTaken(timeTaken) {
  const hours = Math.floor(timeTaken);
  const minutes = Math.round((timeTaken - hours) * 60);

  const hoursString = hours > 0 ? `${hours}h` : '';
  const minutesString = minutes > 0 ? `${minutes} mins` : '';

  if (hoursString && minutesString) {
    return `${hoursString} and ${minutesString}`;
  } else {
    return hoursString || minutesString || '0 mins';
  }
}

export const graphOptionsForEventReports = {
  plugins: {
    legend: {
      display: false,
      labels: {
        font: 'Literata',
        textAlign: 'left'
      }
    }
  },
  responsive: true,
  scales: {
    x: {
      grid: {
        display: false
      },
      stacked: true
    },
    y: {
      beginAtZero: true,
      grid: {
        display: false
      },
      max: 10,
      min: 0,
      // stacked: true,
      stepSize: 1,
      ticks: {
        callback: (value) => value + ' hr'
      }
    }
  }
};

export const transOrderStatusesEnums = {
  COMPLETE_IA_REVENUE_PAID: 'Complete IA Revenue Paid',
  DOCUMENTATION: 'Documentation',
  MICRO_MARKET: 'Micro-market',
  NEGOTIATION: 'Negotiation',
  REGISTRATION: 'Registration'
};

export const nonTransOrderStatusesEnums = {
  INSPECTION: 'Inspection',
  ORDER_CANCELLED: 'Order cancelled',
  ORDER_CLOSED: 'Order closed',
  PAYMENT: 'Payment',
  QUOTATION: 'Quotation',
  REQUEST_PLACED: 'Request placed',
  WIP: 'WIP'
};

export const transOrderSubStatusesEnums = {
  ADDITIONAL_DOCUMENTATION_CHARGES: 'Additional documentation charges',
  NEGOTIATION_FINALISED: 'Negotiation finalised',
  ONLINE_CLASSIFIED_LISTING: 'Online classified listing',
  PARTIAL_IA_REVENUE_PAID: 'Partial IA revenue paid',
  POST_REGISTRATION_IN_PROGRESS: 'Post registration in progress',
  PROPERTY_MAINTENANCE_REQUIREMENT: 'Property maintenance requirement',
  PROSPECTIVE_BUYERS_OR_SELLER_HUNT: 'Prospective buyers/seller hunt',
  TOKEN_ADVANCE_PAID: 'Token advance paid',
  TOTAL_IA_REVENUE_PAID: 'Total IA revenue paid'
};

export const nonTransOrderSubStatusesEnums = {
  FINAL_PAYMENT_AND_FEEDBACK: 'Final payment & feedback',
  INVOICE_UPLOADED: 'Invoice uploaded',
  PAYMENT_DONE_BY_CLIENT: 'Payment received from client',
  POST_COMPLETION_DOC_UPLOADED: 'Post order completion document',
  POST_COMPLETION_INSPECTION: 'Post order completion inspection',
  PRE_REQUISITE_DOC_UPLOADED: 'Pre-requisite document uploaded',
  PROPERTY_INSPECTION_COMPLETED: 'Inspection completed and requirements finalised',
  QUOTATION_RECEIVED_FROM_VENDOR: 'Quotation received from vendor',
  QUOTATION_SENT_TO_CLIENT: 'Quotation sent to client',
  UPDATES_ON_ORDER: 'Updates on order'
};

export const saleAdvisoryEnums = {
  CALL_SCHEDULED: 'Call Scheduled',
  INSPECTION: 'Inspection',
  REPORT_READY: 'Report Ready',
  REPORT_UPLOADED: 'Report Uploaded',
  REQUESTED: 'Requested'
};

export const saleAdvisorySubEnums = {
  ADVISORY_REPORT_CREATED: 'Advisory report created',
  CONNECTED_WITH_CLIENT: 'Connected with client',
  FEEDBACK_FROM_CLIENT: 'Feedback from client',
  FINAL_REPORT_UPLOADED: 'Final report uploaded',
  PROPERTY_INSPECTION_BY_FOS: 'Property inspection by FOS',
  SCHEDULE_A_CALL_BACK: 'Schedule a call back'
};

export const purchaseAdvisoryEnums = {
  ADVIOSRY_REPORT_CREATED: 'Adviosry report created',
  CONNECTED_WITH_CLIENT: 'Connected with client',
  FINAL_FROM_CLIENT: 'Feedback from Client',
  FINAL_REPORT_UPLOADED: 'Final Report uploaded',
  SCHEDULE_A_CALL_BACK: 'Schedule a call back'
};

export const taxAdvisoryEnums = {
  CONNECTED_WITH_CLIENT: 'Connected with client',
  FINAL_FROM_CLIENT: 'Feedback from Client',
  FINAL_REPORT_UPLOADED: 'Final Report uploaded',
  SCHEDULE_A_CALL_BACK: 'Schedule a call back'
};

export const marketInsightsEnums = {
  FINAL_FROM_CLIENT: 'Feedback from Client',
  FINAL_REPORT_UPLOADED: 'Final Report uploaded'
};

export const taxAssessmentEnums = {
  ASSESSMENT_ONGOING: 'Assessment',
  ORDER_COMPLETED: 'Order Completed',
  ORDER_PLACED: 'Order placed',
  PAYMENT: 'Payment',
  QUOTATION: 'Quotation'
};

export const taxAssessmentSubEnums = {
  CREATE_ASSESSMENT: 'Create assessment',
  CREATE_QUOTATION: 'Create quotation',
  FEEDBACK_FROM_CLIENT: 'Feedback from Client',
  PAYMENT_CREATED: 'Payment created',
  TAX_PAID_RECEIPT_SALE_DEED_INSPECTION: 'Tax paid receipt/Sale deed inspection',
  TAX_RECEIPT_UPLOADED: 'Tax receipt uploaded'
};

export const dashboardGraphData = {
  datasets: [
    {
      backgroundColor: ['#9FC088', '#DF5E5E'],
      data: [40, 60],
      label: 'No. of leads'
    }
  ],
  labels: ['Open Leads', 'Closed Leads']
};

export const dashboardOptionsTotalBuyersData = {
  cutout: 60,
  elements: {
    arc: {
      borderWidth: 0
    }
  },
  plugins: {
    legend: {
      display: false
    }
  }
};

export const dashboardOptions = {
  cutout: 70,
  elements: {
    arc: {
      borderWidth: 0
    }
  },
  plugins: {
    doughnutBackground: {
      color: '#E4E6E6',
      enabled: true
    },
    legend: {
      display: false
    }
  }
};

export const dashboardAdExpiringData = {
  datasets: [
    {
      backgroundColor: ['#95BDFF', '#E9EDC9', '#FFAACF', 'transparent'],
      borderWidth: 0,
      data: [16.66, 16.66, 16.66, 50],
      hoverBackgroundColor: ['#FF6384', 'transparent']
    }
  ],
  labels: ['Label 1', '']
};

export const dashboardAdExpiringOptions = {
  cutout: 120,
  plugins: {
    legend: {
      display: false
    },
    tooltip: {
      enabled: false
    }
  }
};

export const dashboardFilterMenuItems = [
  { label: 'Today', value: 'Today' },
  { label: 'Yesterday', value: 'Yesterday' },
  { label: 'This Week', value: 'WTD' },
  { label: 'This Month', value: 'MTD' },
  { label: 'This Quarter', value: 'QTD' },
  { label: 'This Year', value: 'YTD' },
  { label: 'Custom', value: 'Custom' },
  { label: 'LifeTime', value: 'LifeTime' }
];

export function getCurrentFinancialQuarter() {
  const today = moment();
  const startOfYear = moment(today).startOf('year');

  let startQuarter = startOfYear;
  let endQuarter = startOfYear;

  // Determine the financial quarter
  const quarter = Math.floor((today.month() - startQuarter.month()) / 3) + 1;

  // Calculate start and end dates of the current financial quarter with the current year
  startQuarter.set('month', (quarter - 1) * 3);
  endQuarter = moment(startQuarter).endOf('quarter');

  return {
    end: endQuarter.format('YYYY-MM-DD'),
    start: startQuarter.format('YYYY-MM-DD')
  };
}

const getFinancialYear = () => {
  const currentMonth = moment().month();
  const currentYear = moment().year();

  // If the current month is before April, the financial year started in the previous year
  if (currentMonth < 3) {
    return {
      fromDate: moment([currentYear - 1, 3, 1]).format('YYYY-MM-DD'),
      toDate: moment([currentYear, 2, 31]).format('YYYY-MM-DD')
    };
  }

  // Otherwise, the financial year started in the current year and ends in the next year
  return {
    fromDate: moment([currentYear, 3, 1]).format('YYYY-MM-DD'),
    toDate: moment([currentYear + 1, 2, 31]).format('YYYY-MM-DD')
  };
};

export const dateMappings = {
  Default: { fromDate: moment().format('YYYY-MM-DD'), toDate: '2014-01-01' },
  LifeTime: { fromDate: '2000-01-01', toDate: moment().format('YYYY-MM-DD') },
  MTD: {
    fromDate: moment().clone().startOf('month').format('YYYY-MM-DD'),
    toDate: moment().clone().endOf('month').format('YYYY-MM-DD')
  },
  QTD: {
    fromDate: getCurrentFinancialQuarter().start,
    toDate: getCurrentFinancialQuarter().end
  },
  Today: { fromDate: moment().format('YYYY-MM-DD'), toDate: moment().format('YYYY-MM-DD') },
  WTD: {
    fromDate: moment().clone().subtract(7, 'days').format('YYYY-MM-DD'),
    toDate: moment().clone().add(1, 'days').format('YYYY-MM-DD')
  },
  YTD: getFinancialYear(),
  Yesterday: {
    fromDate: moment().subtract(1, 'days').format('YYYY-MM-DD'),
    toDate: moment().subtract(1, 'days').format('YYYY-MM-DD')
  }
};

export const Options = [
  { id: 1, txt: 'Yes', value: true },
  { id: 2, txt: 'No', value: false }
];

export const nonTransactionalData = [
  {
    color2: 'rgba(164, 150, 113, 1)',
    color3: 'rgba(164, 150, 113, 1)',
    iconColor: 'rgba(255, 235, 180, 1)',
    name: 'New'
  },
  {
    color2: 'rgba(86, 99, 107, 1)',
    color3: 'rgba(86, 99, 107, 1)',
    iconColor: 'rgba(157, 178, 191, 1)',
    name: 'Quotation Pending'
  },
  {
    color2: 'rgba(110, 102, 125, 1)',
    color3: 'rgba(110, 102, 125, 1)',
    iconColor: 'rgba(191, 172, 226, 1)',
    name: 'Quotation Accepted'
  },
  {
    color2: 'rgba(102, 86, 74, 1)',
    color3: 'rgba(102, 86, 74, 1)',
    iconColor: 'rgba(206, 171, 147, 1)',
    name: 'WIP'
  },
  {
    color2: 'rgba(153, 94, 82, 1)',
    color3: 'rgba(153, 94, 82, 1)',
    iconColor: 'rgba(250, 152, 132, 1)',
    name: 'Cancelled'
  }
];

export const transactionalData = [
  {
    color2: 'rgba(169, 114, 114, 1)',
    color3: 'rgba(169, 114, 114, 1)',
    iconColor: 'rgba(255, 171, 171, 1)',
    name: 'New'
  },
  {
    color2: 'rgba(185, 141, 81, 1)',
    color3: 'rgba(144, 109, 62, 1)',
    iconColor: 'rgba(255, 194, 111, 1)',
    name: 'WIP'
  },
  {
    color2: 'rgba(106, 126, 116, 1)',
    color3: 'rgba(106, 126, 116, 1)',
    iconColor: 'rgba(181, 213, 197, 1)',
    name: 'Pre-Closure'
  },
  {
    color2: 'rgba(105, 144, 164, 1)',
    color3: 'rgba(105, 144, 164, 1)',
    iconColor: 'rgba(145, 200, 228, 1)',
    name: 'Closure'
  },
  {
    color2: 'rgba(149, 65, 45, 1)',
    color3: 'rgba(149, 65, 45, 1)',
    iconColor: 'rgba(226, 94, 62, 1)',
    name: 'Dropped'
  }
];

export const chartsData = {
  nonTransactional: [
    { color: '#FFEBB4', label: 'New', value: 100 },
    { color: '#FA9884', label: 'Cancelled', value: 20 },
    { color: '#BFACE2', label: 'Quotation Accepted', value: 40 },
    { color: '#9DB2BF', label: 'Quotation Pending', value: 80 },
    { color: '#CEAB93', label: 'WIP', value: 120 }
  ],
  transactional: [
    { color: '#FFABAB', label: 'New', value: 100 },
    { color: '#E25E3E', label: 'Dropped', value: 20 },
    { color: '#91C8E4', label: 'Closure', value: 40 },
    { color: '#B5D5C5', label: 'Pre-Closure', value: 80 },
    { color: '#FFC26F', label: 'WIP', value: 120 }
  ]
};

export const frequencyOptions = [
  {
    name: '1 month',
    value: 1
  },
  {
    name: '2 months',
    value: 2
  },
  {
    name: '3 months',
    value: 3
  },
  {
    name: '4 months',
    value: 4
  },
  {
    name: '5 months',
    value: 5
  },
  {
    name: '6 months',
    value: 6
  },
  {
    name: '7 months',
    value: 7
  },
  {
    name: '8 months',
    value: 8
  },
  {
    name: '9 months',
    value: 9
  },
  {
    name: '10 months',
    value: 10
  },
  {
    name: '11 months',
    value: 11
  },
  {
    name: '12 months',
    value: 12
  }
];

export const loanStatusColorsEnums = {
  approved: '#13942F',
  cancelled: 'rgba(10, 58, 103, 0.15)',
  new: '#0A3A67',
  rejected: '#FF6B6B'
};

export const filterEnums = {
  APP_DOWNLOADS: 'App downloads',
  ASSIGN_TO: 'Assign To',
  BRANCH: 'Branch',
  BRANCH_HEAD: 'Branch Head',
  BUYER_SOURCE: 'Buyer Source',
  BUYER_STATUS: 'Buyer Status',
  BUYER_TYPE: 'Buyer Type',
  CALLBACK_STATUS: 'Callback Status',
  CHANNEL_PARTNER: 'Channel partner',
  CHANNEL_PARTNER_VERTICAL: 'Channel partner vertical',
  COUNTRY: 'Country',
  CREATED_FROM_DATE: 'Created From Date',
  CREATED_TO_DATE: 'Created To Date',
  CUSTOMER_TYPE: 'Customer type',
  DATE: 'Date',
  DELETED: 'Deleted',
  EKYC: 'EKYC',
  IA_VALUATION: 'IA Valuation',
  INSIGHTS: 'Insights',
  LEAD_SOURCE: 'Lead Source',
  LOAN_STATUS: 'Loan Status',
  LOAN_TYPE: 'Loan Type',
  LOCALITY: 'Locality',
  MAX_PRICE_RANGE: 'Max Price Range',
  MAX_PROFILE_COMPLETION: 'Max Profile Completion',
  MIN_PRICE_RANGE: 'Min Price Range',
  MIN_PROFILE_COMPLETION: 'Min Profile Completion',
  ORDER_CATEGORY: 'Order Category',
  ORDER_STATUS: 'Order Status',
  ORDER_TYPE: 'Order Type',
  PAYMENT_FROM_DATE: 'Payment From Date',
  PAYMENT_TO_DATE: 'Payment To Date',
  PREMIUM_TYPE: 'Premium type',
  PRICE_RANGE: 'Price Range',
  PROFILE_COMPLETION: 'Profile Completion',
  PROPERTY_CATEGORY: 'Property Category',
  PROPERTY_STATUS: 'Property Status',
  PROPERTY_TYPE: 'Property Type',
  REFERRAL_TYPE: 'Referral Type',
  REQUESTED_FROM_DATE: 'Requested From Date',
  REQUESTED_TO_DATE: 'Requested To Date',
  REVENUE_COLLECTED_BY: 'Revenue Collected By',
  REVENUE_TYPE: 'Revenue Type',
  REWM: 'REWM',
  RM: 'RM',
  SORT_AT: 'Sort At',
  SORT_BY: 'Sort by',
  SOURCE: 'Source',
  SUB_ASSIGN_TO: 'Sub Assign To',
  TEMPERATURE: 'Temperature',
  TRANSACTION_TYPE: 'Transaction Type',
  UPDATED_FROM_DATE: 'Updated From Date',
  UPDATED_TO_DATE: 'Updated To Date'
};
