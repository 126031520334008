import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
  AccessAlarmsOutlined as AccessAlarmsOutlinedIcon,
  BorderColorOutlined as BorderColorOutlinedIcon,
  Close as CloseIcon,
  CloudUploadOutlined as CloudUploadOutlinedIcon,
  Delete as DeleteIcon,
  Description as DescriptionIcon,
  Download as DownloadIcon,
  ExpandMore as ExpandMoreIcon
} from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import PropTypes from 'prop-types';
import { handleDocRename, handleDocRenewal, handleFileUpload } from 'store/propertySlice';
import { scrollToErrors } from 'utils';
import { frequencyOptions } from 'utils/enums';
import propertyDocsStyles from './UploadDocs.styles';

export default function PropertyDocs({
  clientId,
  getFiles,
  deleteFile,
  files,
  type,
  handleGetDocumentReminder,
  propId
}) {
  const classes = propertyDocsStyles();
  const dispatch = useDispatch();

  const [showRenewal, setShowRenewal] = useState(false);
  const [selectedFile, setSelectedFile] = useState('');
  const [selectedRenamedFile, setSelectedRenamedFile] = useState('');
  const [openRenameModal, setOpenRenameModal] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [renewDoc, setRenewDoc] = useState(false);

  useEffect(() => {
    if (clientId) {
      getFiles(clientId);
    }
  }, [clientId]);

  const initialValues = {
    renewalAt: null,
    renewalFrequency: ''
  };

  const initialValues2 = {
    newName: ''
  };

  const validationSchema = Yup.object().shape({
    renewalAt: Yup.string().required('Renewal Date is required'),
    renewalFrequency: Yup.string().required('Frequency is required')
  });

  const validationSchema2 = Yup.object().shape({
    newName: Yup.string().typeError('Enter valid name')
  });

  const uploadDoc = async (files) => {
    await uploadFile(files);

    handleClickOpenDialog();
  };

  const uploadFile = async (files) => {
    const form = new FormData();

    const arr = Array.prototype.slice.call(files);
    arr?.forEach((file) => form.append('file', file));

    if (propId) {
      form.append('property_id', propId);
      await dispatch(handleFileUpload(clientId, form));
    }

    await getFiles(clientId);
  };

  const handleDocumentRenewal = async (values) => {
    const params = {
      files: [
        {
          file_id: selectedFile,
          renewal: true,
          renewal_at: values?.renewalAt ? moment(values?.renewalAt)?.format('YYYY-MM-DD') : null,
          renewal_frequency: values?.renewalFrequency || ''
        }
      ]
    };

    await dispatch(handleDocRenewal(clientId, params));

    setShowRenewal(false);
    setSelectedFile('');
    setRenewDoc(false);
    getFiles(clientId);
    handleGetDocumentReminder(clientId, propId);
  };

  const handleDocumentRename = async (values) => {
    const params = {
      files: [
        {
          file_id: selectedRenamedFile,
          file_name: values?.newName
        }
      ]
    };

    await dispatch(handleDocRename(clientId, params));
    setOpenRenameModal(false);
    setSelectedRenamedFile('');
    getFiles(clientId);
  };

  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <Box className={classes.main}>
      <Box>
        <Typography sx={{ fontSize: '1rem', fontWeight: '500' }}>
          {type === 'Property' ? 'Property Documents' : 'Order Documents'}
        </Typography>

        <Box className={classes.box} sx={{ borderBottom: '0' }}>
          <Button fullWidth variant="outlined" component="label">
            <CloudUploadOutlinedIcon sx={{ color: '#B2C0CE', fontSize: '48px' }} />
            <Typography>Upload File</Typography>
            <input
              type="file"
              hidden
              accept="application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, image/*"
              multiple
              onChange={(event) => uploadDoc(event.target.files)}
            />
          </Button>
        </Box>
      </Box>

      <Box sx={{ maxHeight: '500px', overflow: 'scroll' }}>
        {files.length > 0 &&
          files?.map((file) => (
            <>
              {renewDoc && (
                <Modal open={renewDoc} onClose={() => setRenewDoc(false)} className={classes.modal}>
                  <Box className={classes.modalContainer}>
                    <Box className={classes.flex}>
                      <Box className={classes.modalTitle}>
                        <Typography sx={{ fontSize: '16px' }}>Renew Document</Typography>
                      </Box>
                      <Button
                        variant="outlined"
                        size="small"
                        sx={{
                          borderColor: 'rgba(10, 58, 103, 0.6)',
                          borderWidth: '2px'
                        }}
                        onClick={() => setRenewDoc(false)}>
                        <CloseIcon sx={{ color: 'rgba(10, 58, 103, 0.6)' }} />
                      </Button>
                    </Box>
                    <Divider sx={{ color: 'rgba(10, 58, 103, 0.15)', margin: '32px 0' }} />
                    <Box
                      sx={{
                        backgroundColor: '#fff',
                        borderRadius: '10px',
                        padding: '1.5rem 1rem'
                      }}>
                      <Formik
                        enableReinitialize={true}
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleDocumentRenewal}>
                        {({ errors, handleSubmit, isValid, setFieldValue, touched, values }) => {
                          return (
                            <Box>
                              <Box sx={{ alignItems: 'center', display: 'flex', gap: 4 }}>
                                <FormLabel id="renewal" sx={{ color: 'rgba(10, 58, 103, 0.80)' }}>
                                  Next date of renewal?
                                </FormLabel>
                                <FormControl sx={{ marginBottom: '0 !important', width: '30%' }}>
                                  <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <DatePicker
                                      value={values?.renewalAt}
                                      inputFormat="YYYY-MM-DD"
                                      onChange={(newValue) => setFieldValue('renewalAt', newValue)}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          helperText={touched.renewalAt && errors.renewalAt}
                                          error={touched.renewalAt && Boolean(errors.renewalAt)}
                                        />
                                      )}
                                    />
                                  </LocalizationProvider>
                                </FormControl>
                                <FormControl sx={{ width: '30%' }}>
                                  <InputLabel id="renewalFrequency">Frequency</InputLabel>
                                  <Select
                                    id="renewalFrequency"
                                    name="renewalFrequency"
                                    labelId="renewalFrequency"
                                    onChange={(e) =>
                                      setFieldValue('renewalFrequency', e.target.value)
                                    }>
                                    {frequencyOptions?.map((item) => (
                                      <MenuItem key={item.value} value={item.value}>
                                        {item.name}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                  {touched.renewalFrequency && errors.renewalFrequency ? (
                                    <FormHelperText sx={{ color: '#bf3333' }}>
                                      {touched.renewalFrequency && errors.renewalFrequency}
                                    </FormHelperText>
                                  ) : null}
                                </FormControl>
                              </Box>

                              <Box className={classes.btnBox}>
                                <Button
                                  variant="outlined"
                                  className={classes.cancelBtn}
                                  onClick={() => setRenewDoc(false)}>
                                  Cancel
                                </Button>
                                <Button
                                  variant="contained"
                                  className={classes.saveBtn}
                                  onClick={() =>
                                    isValid ? handleSubmit() : scrollToErrors(errors)
                                  }>
                                  Save
                                </Button>
                              </Box>
                            </Box>
                          );
                        }}
                      </Formik>
                    </Box>
                  </Box>
                </Modal>
              )}

              {openDialog && (
                <Dialog open={openDialog} onClose={handleCloseDialog}>
                  <DialogContent>
                    <DialogContentText>Do you want to renew this document?</DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={() => {
                        setRenewDoc(true);
                        setSelectedFile(files?.[0]?.file_id);
                        handleCloseDialog();
                      }}
                      autoFocus>
                      Yes
                    </Button>
                    <Button
                      onClick={() => {
                        handleCloseDialog();
                      }}>
                      No
                    </Button>
                  </DialogActions>
                </Dialog>
              )}

              <Accordion key={file?.file_id} className={classes.accordian}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Box sx={{ alignItems: 'center', display: 'flex', gap: 4 }}>
                    <DescriptionIcon sx={{ color: '#0A3A67' }} />
                    <Typography sx={{ color: '#0A3A67' }}>{file?.file_name}</Typography>
                    <Button
                      variant="contained"
                      onClick={() => {
                        setSelectedRenamedFile(file?.file_id);
                        setOpenRenameModal(true);
                      }}
                      className={classes.renameBtn}>
                      Rename
                    </Button>
                    {moment(file?.renewal_at).diff(moment(), 'hours') <= 24 && (
                      <AccessAlarmsOutlinedIcon sx={{ color: '#75013D' }} />
                    )}
                    <DeleteIcon
                      onClick={() => {
                        deleteFile(file?.file_id);
                      }}
                      sx={{
                        '&:hover': {
                          color: '#0A3A67'
                        },
                        boxSizing: 'border-box',
                        color: 'rgba(10, 58, 103, 0.60)'
                      }}
                    />
                    <IconButton
                      onClick={() => window.open(file?.file_url, '_blank')}
                      className={classes.closeBtn}
                      sx={{ marginRight: '12px' }}>
                      <DownloadIcon sx={{ color: '#0A3A67' }} />
                    </IconButton>
                  </Box>

                  {openRenameModal && (
                    <Modal
                      open={open}
                      onClose={() => setOpenRenameModal(false)}
                      className={classes.modal}>
                      <Box className={classes.modalContainer}>
                        <Box className={classes.flex}>
                          <Box className={classes.modalTitle}>
                            <Typography sx={{ fontSize: '16px' }}>Rename Document</Typography>
                          </Box>
                          <Button
                            variant="outlined"
                            size="small"
                            sx={{
                              borderColor: 'rgba(10, 58, 103, 0.6)',
                              borderWidth: '2px'
                            }}
                            onClick={() => setOpenRenameModal(false)}>
                            <CloseIcon sx={{ color: 'rgba(10, 58, 103, 0.6)' }} />
                          </Button>
                        </Box>
                        <Divider sx={{ color: 'rgba(10, 58, 103, 0.15)', margin: '32px 0' }} />

                        <Box
                          sx={{
                            backgroundColor: 'rgba(255, 255, 255, 1)',
                            borderRadius: '24px',
                            padding: '24px',
                            position: 'relative'
                          }}>
                          <Formik
                            enableReinitialize
                            initialValues={initialValues2}
                            validationSchema={validationSchema2}
                            onSubmit={handleDocumentRename}>
                            {({
                              errors,
                              handleBlur,
                              handleChange,
                              touched,
                              values,
                              handleSubmit
                            }) => {
                              return (
                                <>
                                  <FormControl>
                                    <FormLabel>New Name</FormLabel>
                                    <TextField
                                      id="newName"
                                      value={values?.newName}
                                      onChange={handleChange}
                                      placeholder="Ex: New File"
                                      onBlur={handleBlur}
                                      error={touched.newName && Boolean(errors.newName)}
                                      helperText={touched.newName && errors.newName}
                                    />
                                  </FormControl>
                                  <Box>
                                    <Button variant="outlined" type="submit" onClick={handleSubmit}>
                                      Save
                                    </Button>
                                  </Box>
                                </>
                              );
                            }}
                          </Formik>
                        </Box>
                      </Box>
                    </Modal>
                  )}
                </AccordionSummary>
                <AccordionDetails>
                  <Divider />
                  <Box sx={{ padding: '1rem 0' }}>
                    <Box
                      sx={{
                        alignItems: 'center',
                        display: 'flex',
                        gap: 9,
                        marginBottom: '1.5rem'
                      }}>
                      <Box sx={{ alignItems: 'center', display: 'flex', gap: 2 }}>
                        <Typography sx={{ color: 'rgba(10, 58, 103, 0.40)' }}>
                          Uploaded On
                        </Typography>
                        <Typography sx={{ color: '#0A3A67' }}>
                          {moment(file?.created_at).format('DD/MM/YYYY hh:mm A')}
                        </Typography>
                      </Box>
                      <Box sx={{ alignItems: 'center', display: 'flex', gap: 2 }}>
                        <Typography sx={{ color: 'rgba(10, 58, 103, 0.40)' }}>
                          Last Update
                        </Typography>
                        <Typography sx={{ color: '#0A3A67' }}>
                          {moment(file?.updated_at).format('DD/MM/YYYY hh:mm A') || '-'}
                        </Typography>
                      </Box>
                    </Box>
                    <Box className={classes.flex}>
                      <FormControl
                        sx={{
                          alignItems: 'center',
                          display: 'flex',
                          flexDirection: 'row',
                          gap: 6
                        }}>
                        <FormLabel
                          id="renewal"
                          sx={{ color: 'rgba(10, 58, 103, 0.80)', fontSize: '1rem !important' }}>
                          Does this document need renewal?
                        </FormLabel>
                        <RadioGroup name="renewal" row>
                          <FormControlLabel
                            value="yes"
                            control={
                              <Radio
                                checked={selectedFile === file?.file_id}
                                onChange={() => setSelectedFile(file?.file_id)}
                                disabled={!showRenewal}
                              />
                            }
                            label="Yes"
                          />
                          <FormControlLabel
                            value="no"
                            control={
                              <Radio
                                onChange={() => {
                                  setShowRenewal(false);
                                  setSelectedFile('');
                                }}
                                disabled={!showRenewal}
                              />
                            }
                            label="No"
                          />
                        </RadioGroup>
                      </FormControl>
                      {!showRenewal && (
                        <BorderColorOutlinedIcon
                          onClick={() => {
                            setShowRenewal(true);
                            setSelectedFile(file?.file_id);
                          }}
                          sx={{ color: '#0A3A67', cursor: 'pointer' }}
                        />
                      )}
                    </Box>

                    {showRenewal && (
                      <Formik
                        enableReinitialize={true}
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleDocumentRenewal}>
                        {({ errors, handleSubmit, isValid, setFieldValue, touched, values }) => {
                          return (
                            <Box className={classes.flex}>
                              <Box sx={{ alignItems: 'center', display: 'flex', flex: 1, gap: 4 }}>
                                <FormLabel id="renewal" sx={{ color: 'rgba(10, 58, 103, 0.80)' }}>
                                  Next date of renewal?
                                </FormLabel>
                                <FormControl sx={{ marginBottom: '0 !important', width: '30%' }}>
                                  <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <DatePicker
                                      value={values?.renewalAt}
                                      inputFormat="YYYY-MM-DD"
                                      onChange={(newValue) => setFieldValue('renewalAt', newValue)}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          helperText={touched.renewalAt && errors.renewalAt}
                                          error={touched.renewalAt && Boolean(errors.renewalAt)}
                                        />
                                      )}
                                    />
                                  </LocalizationProvider>
                                </FormControl>
                                <FormControl sx={{ width: '30%' }}>
                                  <InputLabel id="renewalFrequency">Frequency</InputLabel>
                                  <Select
                                    id="renewalFrequency"
                                    name="renewalFrequency"
                                    labelId="renewalFrequency"
                                    onChange={(e) =>
                                      setFieldValue('renewalFrequency', e.target.value)
                                    }>
                                    {frequencyOptions?.map((item) => (
                                      <MenuItem key={item.value} value={item.value}>
                                        {item.name}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                  {touched.renewalFrequency && errors.renewalFrequency ? (
                                    <FormHelperText sx={{ color: '#bf3333' }}>
                                      {touched.renewalFrequency && errors.renewalFrequency}
                                    </FormHelperText>
                                  ) : null}
                                </FormControl>
                              </Box>

                              <Button
                                variant="outlined"
                                className={classes.cancelBtn}
                                sx={{ marginRight: '1rem' }}
                                onClick={() => {
                                  setShowRenewal(false);
                                  setSelectedFile('');
                                }}>
                                Cancel
                              </Button>

                              <Button
                                variant="contained"
                                className={classes.saveBtn}
                                onClick={() => (isValid ? handleSubmit() : scrollToErrors(errors))}>
                                Save
                              </Button>
                            </Box>
                          );
                        }}
                      </Formik>
                    )}

                    {!showRenewal && (
                      <Box sx={{ alignItems: 'center', display: 'flex', gap: 10 }}>
                        <Typography sx={{ color: '#0A3A67' }}>
                          By when do you want to renew next ?
                        </Typography>
                        <Box sx={{ alignItems: 'center', display: 'flex', gap: 4 }}>
                          <Box sx={{ alignItems: 'center', display: 'flex', gap: 1 }}>
                            <Typography sx={{ color: 'rgba(10, 58, 103, 0.60)' }}>Date:</Typography>
                            <Typography sx={{ color: '#0A3A67' }}>
                              {file?.renewal_at
                                ? moment(file?.renewal_at).format('DD-MM-YYYY')
                                : '-'}
                            </Typography>
                          </Box>
                          <Box sx={{ alignItems: 'center', display: 'flex', gap: 1 }}>
                            <Typography sx={{ color: 'rgba(10, 58, 103, 0.60)' }}>
                              Frequency :
                            </Typography>
                            <Typography sx={{ color: '#0A3A67' }}>
                              {file?.renewal_frequency
                                ? file?.renewal_frequency < 1
                                  ? `${file?.renewal_frequency} month`
                                  : `${file?.renewal_frequency} months`
                                : '-'}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    )}
                  </Box>
                </AccordionDetails>
              </Accordion>
            </>
          ))}
      </Box>
    </Box>
  );
}

PropertyDocs.propTypes = {
  clientId: PropTypes.string,
  deleteFile: PropTypes.func,
  files: PropTypes.arr,
  getFiles: PropTypes.func,
  handleGetDocumentReminder: PropTypes.func,
  propId: PropTypes.string,
  type: PropTypes.string
};
